import { ServiceContainer } from '@wsi/web-core/utils/ServiceContainer'

const ServiceContainerSingleton = () => {
  let serviceContainer = null
  return {
    getInstance: function (cfg) {
      if (serviceContainer == null) {
        serviceContainer = new ServiceContainer(cfg)
      }
      return serviceContainer
    }
  }
}

export const serviceContainerSingleton = ServiceContainerSingleton()
