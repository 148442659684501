import { APP_NAME } from "../constants/index";
import { format, getTime } from "date-fns";

import { HttpRequestContract } from "@wsi/web-core/utils/contracts";

export default class TrackService {
  constructor({ distinct_id, httpService }) {
    this.distinct_id = distinct_id;
    this.httpService = httpService;
  }

  alias(data) {
    if (data) {
      data.distinct_id = this.distinct_id;

      const httpRequestContract = new HttpRequestContract(
        "POST",
        `/track/u`,
        {},
        data,
        {parser: 'text'}
      );
      const request =
        this.httpService.generateRequestContract(httpRequestContract);
      return this.httpService.request(request);
    }
  }

  identify(user) {
    try {
      const data = {};
      data.name = "$identify";
      data.payload = {
        $anon_id: this.distinct_id,
        $identified_id: user.id,
      };

      const httpRequestContract = new HttpRequestContract(
        "POST",
        `/track/e`,
        {},
        data
      );
      const request =
        this.httpService.generateRequestContract(httpRequestContract);
      return this.httpService.request(request);
    } catch (error) {
      console.error("UNABLE TO PERFORM USER IDENTIFY", error);
    }
  }

  checkPayload(payload) {
    // GESTIRE ERRORE IN NUOVA GESTIONE HTTP REQUEST
    if (payload.isAxiosError) {
      return {
        baseURL: payload.config.baseURL,
        method: payload.config.method,
        responseType: payload.config.responseType,
        "X-Host-Override": payload.config["X-Host-Override"],
        url: payload.config.url,
        code: payload.code,
      };
    } else {
      return payload;
    }
  }

  track(data) {
    try {
      // DATA MUST BE IN THE FOLLOWINF FORMAT
      // {
      //    name: eventName, e.g 'LOGIN' -> name must be inserted in the /constsnts/event.js file
      //    payload: { ... } // object must containt useful data about the event
      // }

      if (!data.payload) {
        data.payload = {};
      }

      data.payload = this.checkPayload(data.payload);

      data.payload.source = APP_NAME;
      data.payload.distinct_id = this.distinct_id;
      data.payload.userAgent = navigator?.userAgent;
      data.payload.timezone = Intl.DateTimeFormat().resolvedOptions()?.timeZone;
      data.payload.platform = navigator?.userAgentData?.platform;
      data.payload.platformLanguage = document
        ?.querySelector("html")
        ?.getAttribute("lang");
      data.payload.browserUserLanguages = navigator?.languages; // detect history languages opened by user. ex of response: ['en-US', 'en', 'it', 'sq']
      data.payload.browserUserLanguage =
        navigator?.language || navigator?.userLanguage;
      data.payload.cookieEnabled = navigator?.cookieEnabled;
      data.payload.browserOnline = navigator?.onLine;
      data.payload.clientDate = format(new Date(), "yyyy/MM/dd HH:mm:ss");
      data.payload.clientISODate = new Date();
      data.payload.clientTimestamp = getTime(new Date());
      // data.payload.clientPosition = crd

      // SEND DATA TO GOOGLE TAG MANAGER
      this.gtmDataLayerPush(data);

      // SEND DATA TO SERVER
      const httpRequestContract = new HttpRequestContract(
        "POST",
        `/track/e`,
        {},
        data
      );
      const request =
        this.httpService.generateRequestContract(httpRequestContract);
      return this.httpService.request(request);
      // return axios.post(`${this.API_URL}/track/e`, data)
    } catch (error) {
      console.error("UNABLE TO PERFORM DATA TRACKING", error);
    }
  }

  gtmDataLayerPush(data) {
    try {
      window.dataLayer = window.dataLayer || [];
      // eslint-disable-next-line no-undef
      dataLayer?.push({
        ...data,
        event: data?.name,
      });
    } catch (error) {
      console.error("UNABLE TO SEND DATA TO GTM", error);
    }
  }
}
