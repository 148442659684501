export default class GuardService {
  static isLoggedIn (sessionData) {
    if (!sessionData || !sessionData.access_token || !sessionData.tse || !sessionData.refresh_token || !sessionData.access_id) {
      return false
    }
    const now = new Date().getTime()
    if (now > sessionData.tse) {
      return false
    }
    return true
  }
  
  static isBusinessUser (userInfo) {
    if (userInfo?.type === 'business') {
      return true
    } else {
      return false
    }
  }
}
