export default function AuthorizationService () {
  let userScopes = []
  let featureMapping = {}

  function canAccessFeature (featurePath = '') {
    if (Array.isArray(featurePath)) { // On Array, make it recoursive (f1 && f2)
      return featurePath.reduce((acc, ff) => {
        return acc && canAccessFeature(ff)
      }, true)
    }

    const tmp = featurePath.split('.')

    const mapping = tmp.reduce((acc, key) => {
      return acc && acc[key]
    }, featureMapping)

    if (mapping && mapping.length > 0) {
      const hasPemission = mapping.reduce((acc, requestedScope) => {
        return acc && (
          requestedScope === '*' ||
          userScopes.findIndex(s => s === requestedScope) >= 0
        )
      }, true)

      return hasPemission
    }

    return false
  }

  return {
    loadRoles: async (userProfile, token = null) => {
      const scopes = await (await fetch('/api/scopes', {
        method: 'POST',
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ email: userProfile?.email ?? null })
      })).json()

      userScopes = scopes || []
    },

    setFeatureMapping: (map = {}) => {
      featureMapping = map
    },

    canAccessFeature
  }
}
