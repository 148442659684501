// EXTERNAL
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { v5 as uuidv5 } from 'uuid';

// WEB-CORE
import HttpService from '@wsi/web-core/services/HTTPService'
import FederationService from '@wsi/web-core/services/FederationService'
import EventBus from '@wsi/web-core/services/EventBus'

// INTERNAL
import { serviceContainerSingleton } from './utils/ServiceContainerSingleton'
import GuardService from './services/GuardService'
import AuthorizazionService from './services/AuthorizationService'
import TrackService from './services/TrackService'
import useFederated from './composables/useFederated';
import fallbackEvents from '../server/fallbackEvents.json'

// service container
const serviceContainer = serviceContainerSingleton.getInstance()

// debug
const debugMode = localStorage.getItem('debugMode') === 'true'
const debugConfig = localStorage.getItem('debugConfig')
const getEmitCallback = (events) => (eventBus, action, payload) => {
  if (!action.startsWith('DEBUG-')) {
    eventBus.emit(events.DEBUG_LOG_EVENT, {
      action,
      payload: JSON.stringify(payload)
    })
  }
}

let cfg = null
let events = null
fetch('/cfg')
  .then((res) => {
    return res.json()
  })
  .then((data) => {
    cfg = data
    if (debugMode && debugConfig && !cfg.DISABLE_DEBUG_GUI) {
      serviceContainer.setContainerConfig(JSON.parse(debugConfig))
    } else {
      serviceContainer.setContainerConfig(cfg)
    }
    serviceContainer.registerServices({
      federationService: new FederationService(cfg),
    })
  })
  .then(() => {
    return useFederated('B2cEvents')
      .then(e => {
        events = e
      })
      .catch(err => {
        events = fallbackEvents
      })
  })
  .then(() => {
    serviceContainer.registerServices({
      GuardService,
      AuthorizazionService,
      eventBus: debugMode ? new EventBus(getEmitCallback(events)) : new EventBus(),
      events,
      httpService: new HttpService({
        defaultUrl: `${cfg.BE_URL}/`,
        defaultHeaders: {
          ...(cfg.VITE_X_HOST_OVERRIDE ? { 'X-Host-Override': cfg.VITE_X_HOST_OVERRIDE } : {}),
          'Content-Type': 'application/json'
        },
        responseInterceptors: [
          {
            rejected: (error) => { 
              if (window.newrelic) {
                window.newrelic.noticeError(error, { url: error?.config?.url, data: error?.config?.data })
              }
              return Promise.reject(error)
            }
          }
        ]
      }),
      bffHttpService: new HttpService({
        defaultUrl: `${cfg.BFF_API_URL}/`,
        defaultHeaders: {
          'Content-Type': 'application/json'
        },
        responseInterceptors: [
          {
            rejected: (error) => {
              if (window.newrelic) {
                window.newrelic.noticeError(error, { url: error?.config?.url, data: error?.config?.data })
              }
              return Promise.reject(error)
            }
          }
        ]
      }),
      mgaB2feHttpService: new HttpService({
        defaultUrl: `${cfg.MGA_B2FE_URL}/`,
        defaultHeaders: {
          'Content-Type': 'application/json'
        }
      })
    })
  })
  .then(() => {
    return FingerprintJS.load()
      .then(res => {
        serviceContainer.registerServices({
          fingerprint: res
        })
      })
  })
  .then(() => {
    const { BFF_API_URL } = serviceContainer.getContainerConfig()
    const { fingerprint, httpService } = serviceContainer.getServices()
    const b2fHttpService = new HttpService({
      defaultUrl: BFF_API_URL,
      defaultHeaders: {
        ...(cfg.VITE_X_HOST_OVERRIDE ? { 'X-Host-Override': cfg.VITE_X_HOST_OVERRIDE } : {}),
        'Content-Type': 'application/json'
      },
      responseInterceptors: [
        {
          rejected: (error) => { 
            if (window.newrelic) {
              window.newrelic.noticeError(error, { url: error?.config?.url, data: error?.config?.data })
            }
            return Promise.reject(error)
          }
        }
      ]
    })
    
    return fingerprint.get()
      .then(res => {
        const distinct_id = uuidv5(res?.visitorId, '23d8bdf6-75e8-4812-9276-e6c58e4a0e8d') // namespace 23d8bdf6-75e8-4812-9276-e6c58e4a0e8d is a random valid UUIDV4 
        serviceContainer.registerServices({
          trackService: new TrackService({ distinct_id, httpService: b2fHttpService })
        })
      })
  })
  .catch((error) => {
    console.log(error)
  })
  .finally(() => {
    import('./bootstrap')
  })
